import React, { useRef, useEffect, useState } from "react"
import {
  EuiForm,
  EuiSpacer,
  EuiText,
  EuiTextArea,
  EuiRadioGroup,
  EuiRadioGroupOption,
  EuiPanel,
  htmlIdGenerator,
  EuiFormErrorText,
  EuiLoadingSpinner,
} from "@elastic/eui"
import HailCXLayout from "../../components/layout/hailcx-layout"
import HailCXRightImageHeader from "../../components/layout/header/hailcx-right-image-header"
import { useStore } from "../../stores"
import { observer } from "mobx-react-lite"
import { Formik, FormikProps, FormikValues } from "formik"
import "../../styles/hailcx-forms.css"
import { useLocation } from "@reach/router"
import { CancelBookingFor } from "../../models"
import { navigate } from "gatsby"
import HailCXDoubleButtonFooter from "../../components/layout/footer/hailcx-double-button-footer"

const CancelPage = () => {
  const { customerStore, surveyStore, bookingStore } = useStore()
  const { isLoading } = surveyStore
  const idPrefix = htmlIdGenerator()()
  const [radioIdSelected, setRadioIdSelected] = useState(`${idPrefix}0`)
  const otherCommentInputId = "OtherReasonInput"

  const formRef = useRef<FormikProps<FormikValues>>(null)
  const [radios, setRadios] = useState<EuiRadioGroupOption[]>(new Array())

  const [otherReasonIsRequired, setOtherReasonIsRequired] = useState(false)
  const [isOperationProcessing, setOperationProcessing] = useState(false)

  const data = {
    title: "Cancellation",
    buttonContent: "Cancel booking",
    secondaryContent: "Back",
  }

  const formState: CancelBookingFor = {
    comments: "",
    cancelReason: "",
  }

  useEffect(() => {
    if (!bookingStore.currentBooking) {
      alert(
        "We noticed you have cancelled your booking. \nWe are redirecting you to the booking screen; hope that you will book with us again!"
      )
      navigate(`/book?eqn=${customerStore.encryptedQuoteNumber}`)
      return
    }

    surveyStore.loadCancelSurveyOptions().then(() => {
      loadsurveyOption()
    })
  }, [])

  const otherTextRef = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    //if other option/radio button is selected we set focus on the comments textbox and bring it into view
    if (radioIdSelected === otherCommentInputId) {
      //console.log('setting focus and scrolling into view...')
      //otherTextRef.current?.focus(); //avoiding focus since iPhone does weird behaviour of scrolling to the bottom of the screen with focus as it brings up keyboard
      otherTextRef.current?.scrollIntoView({ behavior: "smooth" })
    }
  }, [radioIdSelected])

  const handleClick = () => {
    onCancelReasonChange(radioIdSelected)
    if (formRef.current) {
      formRef.current.handleSubmit()
    }
  }

  const handleSubmit = async () => {
    const { comments, cancelReason } = formRef.current
      ?.values as CancelBookingFor
    let surveyId = surveyStore.cancelSurveyOptions?.surveyId || ""
    let customerId = customerStore.customer?.id || ""
    let bookingId = bookingStore.bookingNumber || ""

    //don't process if reason hasn't been provided
    if (cancelReason == "Other" && comments.trim() == "") {
      setOtherReasonIsRequired(true)
      return
    }

    console.log(`cancel reason: ${cancelReason}, comments: ${comments}`)

    setOperationProcessing(true)

    //call api
    await bookingStore.cancelBooking(
      bookingId,
      customerId,
      surveyId,
      cancelReason,
      comments
    )

    //navigate to next screen
    navigate("/book/confirm-cancel")

    //setOperationProcessing(false);
  }

  const onCancelReasonChange = (optionId: string) => {
    if (optionId === otherCommentInputId) {
      formRef.current?.setFieldValue("cancelReason", "Other")
    } else {
      let selectedLabel = radios.find(x => x.id === optionId)?.label
      formRef.current?.setFieldValue("cancelReason", selectedLabel)
      formRef.current?.setFieldValue("comments", "")
    }

    setRadioIdSelected(optionId)

    //reset required state of other reason
    setOtherReasonIsRequired(false)
  }

  const loadsurveyOption = () => {
    let arrLength = surveyStore.cancelSurveyOptions?.options.length || 0
    let options = new Array()
    for (var i = 0; i < arrLength; i++) {
      options.push({
        id: `${idPrefix}${i}`,
        label: surveyStore.cancelSurveyOptions?.options[i],
      })
    }
    options.push({
      id: otherCommentInputId,
      label: "Other reason:",
    })
    setRadios(options)
  }

  return (
    <HailCXLayout
      header={
        <HailCXRightImageHeader
          title={data.title}
          progressValue={0}
          vehicleInfo={customerStore.getVehicleInfo()}
        />
      }
      footer={
        <HailCXDoubleButtonFooter
          primaryContent={data.buttonContent}
          handlePrimaryClick={handleClick}
          primaryButtonColor="danger"
          primaryButtonDisabled={radios.length === 0 || isOperationProcessing}
          secondaryContent={data.secondaryContent}
          handleSecondaryClick={() => navigate("/book/manage-booking")}
          secondaryIconRequired={true}
        />
      }
    >
      <EuiText className="eui-textLeft">
        <p>
          You're cancelling your booking for now. <br />
          Please let us know why:
        </p>
      </EuiText>
      <EuiSpacer size="m" />
      {radios.length === 0 ? (
        <div>
          <EuiLoadingSpinner size="xl" />
          <EuiText>Loading options...</EuiText>
        </div>
      ) : (
        <EuiPanel>
          <Formik
            enableReinitialize
            initialValues={formState}
            innerRef={formRef}
            onSubmit={handleSubmit}
          >
            {() => (
              <EuiForm component="form">
                <div
                  className="radio"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "right ",
                  }}
                >
                  <EuiRadioGroup
                    options={radios}
                    idSelected={radioIdSelected}
                    onChange={id => onCancelReasonChange(id)}
                    name="cancelReason"
                    className="radio-button-group eui-textLeft"
                    style={{}}
                  />
                  <EuiFormErrorText
                    hidden={!otherReasonIsRequired}
                    style={{ padding: 0 }}
                  >
                    Please provide reason
                  </EuiFormErrorText>
                  <EuiTextArea
                    id="comments"
                    name="comments"
                    placeholder="Describe your reason for cancelling"
                    onChange={formRef.current?.handleChange}
                    value={formRef.current?.values.comments}
                    disabled={!(radioIdSelected === otherCommentInputId)}
                    required={radioIdSelected === otherCommentInputId}
                    inputRef={otherTextRef}
                    style={{ fontSize: 16 }}
                  />
                </div>
              </EuiForm>
            )}
          </Formik>
        </EuiPanel>
      )}
    </HailCXLayout>
  )
}

export default observer(CancelPage)
